@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: 'Poppins';
		src: url('assets/fonts/Poppins-Light.ttf') format('truetype');
		font-weight: 300;
	}

	@font-face {
		font-family: 'Poppins';
		src: url('assets/fonts/Poppins-Bold.ttf') format('truetype');
		font-weight: bold;
	}

	@font-face {
		font-family: 'Poppins';
		src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
		font-weight: normal;
	}

	@font-face {
		font-family: 'Poppins';
		src: url('assets/fonts/Poppins-SemiBold.ttf') format('truetype');
		font-weight: 600;
	}

	html {
		font-family: 'Poppins', sans-serif;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #eff6ff;
}

#root {
	height: 100vh;
}

#confirmWindowBackdrop {
	position: fixed;
	background-color: rgba(95, 88, 88, 0.6);
	display: none;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	/* Higher than the z-index of the background */
}

#confirmWindowBackdrop #content {
	/* padding: 10px; */
	width: 637px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loader {
	display: flex;
	align-items: flex-end;
}

.loader > div {
	width: 4px;
	height: 4px;
	background-color: black;
	border-radius: 2px;
	margin: 0 0 7px 4px;
	animation: opacity 0.5s ease infinite alternate;
}

.loader :nth-child(3) {
	animation-delay: 100ms;
}

.loader :nth-child(4) {
	animation-delay: 200ms;
}

@keyframes opacity {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}
