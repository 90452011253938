body {
	background: #edeef1;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.hide-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

/**
 Firefox has a weird redraw bug when using filter: drop-shadow, which tailwinds uses for shadows.
 Switching to box-shadow fixes the issue. This class makes the change barely noticable.
 */
.drop-shadow {
	-webkit-box-shadow: 0px 0px 11px 0px rgba(22, 22, 22, 0.1);
	-moz-box-shadow: 0px 0px 11px 0px rgba(22, 22, 22, 0.1);
	box-shadow: 0px 0px 11px 0px rgba(22, 22, 22, 0.1);
}
